import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import "./IndexSections.css"
import CirclesBig from "../Shapes/CirclesBig"

gsap.registerPlugin(ScrollTrigger)

const IndexSectionOne = () => {
  const headerRef = useRef()
  const bodyRef = useRef()

  useEffect(() => {
    gsap.set(headerRef.current, { opacity: 0 })
    gsap.set(bodyRef.current, { opacity: 0 })
    gsap
      .timeline()
      .to(headerRef.current, {
        opacity: 1.0,
        ease: "back",
        duration: 3,
        delay: 1,
        scrollTrigger: {
          trigger: "#animation-div",
        },
      })
      .to(bodyRef.current, {
        opacity: 1,
        ease: "back",
        duration: 1,
        scrollTrigger: {
          trigger: "#animation-div",
        },
      })
  }, [])

  return (
    <div id="animation-div" className="relative section-spacing">
      <CirclesBig />
      <div className="h-screen max-w-3/4 mx-auto relative">
        <div className="absolute bottom-8">
          <h2
            ref={headerRef}
            id="titleOne"
            className="text-mbr_blue text-center text-5xl md:text-6xl"
          >
            EAST AND WEST
          </h2>
          <p ref={bodyRef} id="bodyOne" className="large text-mbr_blue md:mx-1/6">
            We have experience navigating tech ecosystems and businesses in the
            US, Australia, and China. We excel at translating requirements and
            business goals across regions to develop native and local tech
            products.
          </p>
        </div>
      </div>
    </div>
  )
}

export default IndexSectionOne
