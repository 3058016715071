import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import "./IndexSections.css"
import DownArrow from "../Shapes/DownArrow"

const IndexSectionTitle = () => {
  useEffect(() => {
    gsap.set("#rise", { scale: 1 })
    gsap.from("#rise", {
      // x: "+= 400",
      y: "-= 600",
      duration: 3,
      scale: 0.2,
      opacity: 0,
      ease: "in",
    })

    gsap.set("#madeby", { scale: 1 })
    gsap.from("#madeby", {
      x: "+=400",
      y: "-= 600",
      duration: 3,
      scale: 0.2,
      opacity: 0,
      ease: "in",
    })
  })

  return (
    <div className="relative section-spacing h-screen">
      <div className="h-screen max-w-full lg:max-w-3/4 mx-auto relative">
        <h2 id="madeby" className="text-mbr_orange">
          Made By
        </h2>
        <h2 id="rise" className="text-mbr_orange">
          RISE
        </h2>
      </div>
      <DownArrow />
    </div>
  )
}

export default IndexSectionTitle
