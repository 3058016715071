import React, { useState, useEffect } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import "./CirclesBig.css"

gsap.registerPlugin(ScrollTrigger)

const CirclesBig = () => {
  useEffect(() => {
    gsap.set(".circle-blue", { scale: 1 })
    gsap.from(".circle-blue", {
      x: "+= 400",
      y: 600,
      duration: 4,
      scale: 0.5,
      ease: "back",
      delay: 0,
      scrollTrigger: {
        trigger: "#circles-div",
      },
    })

    gsap.set(".circle-red", { scale: 1, opacity: 1 })
    gsap.from(".circle-red", {
      x: "-= 400",
      y: 600,
      duration: 4,
      scale: 0.5,
      ease: "back",
      opacity: 1,
      delay: 0,
      scrollTrigger: {
        trigger: "#circles-div",
      },
    })
  })

  return (
    <div id="circles-div">
      <svg
        className="circle-blue absolute blue-x responsive-width responsive-position"
        width="445"
        height="445"
        viewBox="0 0 445 445"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="1" cx="222.5" cy="222.5" r="222.5" fill="#B3CEDF" />
      </svg>

      <svg
        className="circle-red absolute red-x responsive-width responsive-position"
        width="445"
        height="445"
        viewBox="0 0 445 445"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.8" cx="222.5" cy="222.5" r="222.5" fill="#E24C38" />
      </svg>
    </div>
  )
}

export default CirclesBig
