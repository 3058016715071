import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import "./IndexSections.css"

gsap.registerPlugin(ScrollTrigger)

const IndexSectionThree = () => {
  const textRef = useRef(null)

  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "#E24C38" })
  }

  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { color: "#004068" })
  }

  useEffect(() => {
    gsap.set("#text-div", { opacity: 0 })
    gsap.to("#text-div", {
      opacity: 1.0,
      ease: "back",
      duration: 4,
      delay: 0,
      scrollTrigger: {
        trigger: "#text-div",
      },
    })
    //   .from("#bodyOne", { opacity: 0, ease: "back", duration: 1 })
  }, [])

  return (
    <Link to="/projects">
      <div className="pl-0 lg:pl-16 pb-20 sm:pb-0 sm:h-screen max-w-3/4 mx-auto relative cursor-pointer my-20 md:my-0">
        <div
          ref={textRef}
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          id="text-div"
          className="flex flex-col text-mbr_blue"
        >
          <h2 className="text-huge">SEE OUR</h2>
          <h2 className="text-huge">PROJECTS</h2>
          <h2 className="text-huge">––{">"}</h2>
        </div>
      </div>
    </Link>
  )
}

export default IndexSectionThree
