import React, { useEffect } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import "./CirclesThree.css"

gsap.registerPlugin(ScrollTrigger)

const CirclesThree = () => {
  useEffect(() => {
    gsap.set(".circle-red-lg", { scale: 1 })
    gsap.set(".circle-red-sm-one", { scale: 1, opacity: 1 })
    gsap.set(".circle-red-sm-two", { scale: 1, opacity: 1 })
    gsap.from(".circle-red-lg", {
      duration: 4,
      scale: 0.8,
      ease: "back",
      delay: 1,
      scrollTrigger: {
        trigger: ".circle-red-lg",
      },
    })
    gsap.from(".circle-red-sm-one", {
      x: "-= 250",
      y: 500,
      duration: 4,
      scale: 0.5,
      delay: 1,
      ease: "back",
      opacity: 0.8,
      scrollTrigger: {
        trigger: ".circle-red-lg",
      },
    })
    gsap.from(".circle-red-sm-two", {
      x: "+= 250",
      y: 500,
      duration: 4,
      scale: 0.5,
      delay: 1,
      ease: "back",
      opacity: 0.8,
      scrollTrigger: {
        trigger: ".circle-red-lg",
      },
    })
  })

  return (
    <div>
      {/* large red */}
      <svg
        className="circle-red-lg absolute responsive-width-lg responsive-position"
        width="445"
        height="445"
        viewBox="0 0 445 445"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.8" cx="222.5" cy="222.5" r="222.5" fill="#E24C38" />
      </svg>
      {/* small red 1 */}
      <svg
        className="circle-red-sm-one absolute responsive-width-sm responsive-position"
        width="214"
        height="214"
        viewBox="0 0 214 214"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.8"
          cx="106.972"
          cy="106.972"
          r="106.972"
          fill="#E24C38"
        />
      </svg>
      {/* small red 2 */}
      <svg
        className="circle-red-sm-two absolute responsive-width-sm responsive-position"
        width="214"
        height="214"
        viewBox="0 0 214 214"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.8"
          cx="106.972"
          cy="106.972"
          r="106.972"
          fill="#E24C38"
        />
      </svg>
    </div>
  )
}

export default CirclesThree
