import React, { useState, useEffect } from "react"
import Layout from "../layouts/Layout"
import IndexSectionTitle from "../components/IndexSections/IndexSectionTitle"
import IndexSectionOne from "../components/IndexSections/IndexSectionOne"
import IndexSectionTwo from "../components/IndexSections/IndexSectionTwo"
import IndexSectionThree from "../components/IndexSections/IndexSectionThree"
import SiteMetadata from "../components/SiteMetadata"

const indexNew = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <SiteMetadata title="Home" description="Portfolio of MADE BY RISE" />
      <IndexSectionTitle />
      <IndexSectionOne />
      <IndexSectionTwo />
      <IndexSectionThree />
    </Layout>
  )
}

export default indexNew
