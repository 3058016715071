import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import "./IndexSections.css"
import ScrollTrigger from "gsap/ScrollTrigger"
import CirclesThree from "../Shapes/CirclesThree"

gsap.registerPlugin(ScrollTrigger)

const IndexSectionTwo = () => {
  // need a separate ref for each?
  const textRef = useRef(null)

  useEffect(() => {
    gsap.set("#titleOne", { opacity: 0 })
    gsap.set("#bodyOne", { opacity: 0 })
    gsap
      .timeline()
      .to("#titleOne", {
        opacity: 1,
        ease: "back",
        duration: 2,
        delay: 1.5,
        scrollTrigger: {
          trigger: "#titleOne",
        },
      })
      .to("#bodyOne", {
        opacity: 1,
        ease: "back",
        duration: 2,
        scrollTrigger: {
          trigger: "#bodyOne",
        },
      })
  })

  return (
    <div className="relative section-spacing">
      <CirclesThree />
      <div className="h-screen max-w-3/4 mx-auto relative">
        <div className="absolute bottom-8 w-full">
          <h2 ref={textRef} id="titleOne" className="text-4xl lg:text-6xl text-mbr_blue text-center">
            COMMITMENT
          </h2>
          <p ref={textRef} id="bodyOne" className="large text-mbr_blue md:mx-1/6">
            We are committed to excellence, delivering against business
            objectives, and being value-driven people to work with.
          </p>
        </div>
      </div>
    </div>
  )
}

export default IndexSectionTwo
